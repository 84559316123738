import './App.scss';
import location from './assets/location.svg'

function App() {
  return (
    <div className="App">
      <div className="App-container">

        <section className="child">
          <div className="App-header">
            <div className="App-name">
              <h1>Ryan DiCenzo</h1>
            </div>
            <div className="App-bio">
              <div id="first" style={{marginLeft: "3px"}}>student</div>{", "}<div id="second">developer</div>, <div id="third">creator</div>
            </div>
            <div className="App-location">
              <img src={location} alt="location: " />
              <p>
                Berkeley, CA
              </p>
            </div>
            <div className="App-socials">
            ✏️ <a href="https://ryandicenzo.com/resume_2021.pdf">resume</a>
              {" "}/{" "}
              💻  <a href="https://github.com/ryandicenzo">github</a>
              {" "}/{" "}         
              🧑‍💻  <a href="https://linkedin.com/in/ryandicenzo">linkedin</a>
            </div>
          </div>    
        </section>

        {/* <section class="child">
          <div className="Bio-container">
          🐻 Student at UC Berkeley
          <br />
          💻 SWE Intern at Tableau
          <br />
          ️☁ Former SWE Intern at Salesforce
          <br />
          ☕️ Surfer, Climber and Coffee Enthusiast
          </div>
        </section> */}
      </div>
    </div>
  );
}

export default App;
